'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrameContextConsumer = exports.FrameContextProvider = exports.useFrame = exports.FrameContext = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var doc = void 0;
var win = void 0;
if (typeof document !== 'undefined') {
  doc = document;
}
if (typeof window !== 'undefined') {
  win = window;
}

var FrameContext = exports.FrameContext = _react2.default.createContext({ document: doc, window: win });

var useFrame = exports.useFrame = function useFrame() {
  return _react2.default.useContext(FrameContext);
};

var FrameContextProvider = FrameContext.Provider,
    FrameContextConsumer = FrameContext.Consumer;
exports.FrameContextProvider = FrameContextProvider;
exports.FrameContextConsumer = FrameContextConsumer;