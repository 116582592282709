import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Calendar, PickerView } from 'antd-mobile';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

import { HostUrlContainer, ReservationsContainer } from 'containers';
import { getOpenTimeSlots } from 'network';
import {
  AggregatedShiftsType,
  LocationGroupReservationsEnabledAndConfiguredType,
} from 'types';
import { getDayNameOfDateEn, useToggle } from 'utils';

import { GuestCountSelection } from '../GuestCountSelection';

import { getTimeRangeForTimeSlot } from '../ReservationsModal.helper';
import { useAvailableTimeValues } from './ReservationsInitialForm.helper';
import {
  buttonIconStyle,
  GuestCountLimitInfo,
  NavigationPlaceholder,
  SectionTitle,
  StyledPhoneOutlined,
  StyledPrimaryButton,
  StyledName,
  StyledTimePicker,
  TimeValue,
} from './ReservationsInitialForm.styled';

type PropertiesType = {
  aggregatedShifts: AggregatedShiftsType;
  locationGroup: LocationGroupReservationsEnabledAndConfiguredType;
  setAvailableTimeSlots: React.Dispatch<React.SetStateAction<number[] | null>>;
};
export const ReservationsInitialForm = ({
  aggregatedShifts,
  locationGroup,
  setAvailableTimeSlots,
}: PropertiesType) => {
  const intl = useIntl();

  const [showCalendarView, setShowCalendarView] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useToggle();

  const {
    name,
    discoverId,
    maxGuestCount,
    publicPhone,
    businessClosures,
    reservationConfig: { minLeadTime, maxLeadTime, turnTime },
  } = locationGroup;

  const { hostUrl } = HostUrlContainer.useContainer();
  const {
    preferredTime,
    setPreferredTime,
    selectedGuestCount,
    setIsEarliestSearchOption,
  } = ReservationsContainer.useContainer();

  const earliestAcceptedReservationTimestamp = useMemo(
    () => moment().add(minLeadTime, 'seconds').unix(),
    [minLeadTime]
  );
  const latestAcceptedReservationTimestamp = useMemo(
    () => moment().add(maxLeadTime, 'seconds').unix(),
    [maxLeadTime]
  );

  const [datePickerValue, setDatePickerValue] = useState(
    earliestAcceptedReservationTimestamp
  );
  const [currentCalendarTimeStamp, setCurrentCalendarTimeStamp] = useState(() =>
    moment.unix(earliestAcceptedReservationTimestamp).startOf('month').unix()
  );
  const [timePickerValue, setTimePickerValue] = useState<number>(
    moment
      .unix(preferredTime)
      .diff(moment.unix(preferredTime).startOf('day'), 'seconds')
  );

  const checkIfDayIsDisabled = useCallback(
    (date: Date) => {
      if (
        moment(date).isBefore(
          moment.unix(earliestAcceptedReservationTimestamp),
          'day'
        )
      ) {
        return true;
      }

      if (
        moment(date).isAfter(
          moment.unix(latestAcceptedReservationTimestamp),
          'day'
        )
      ) {
        return true;
      }

      if (aggregatedShifts[getDayNameOfDateEn(moment(date))].length === 0) {
        return true;
      }

      return !(
        !businessClosures ||
        !businessClosures.some(businessClosure =>
          moment.unix(businessClosure.startsAt).isSame(date, 'day')
        )
      );
    },
    [
      aggregatedShifts,
      businessClosures,
      earliestAcceptedReservationTimestamp,
      latestAcceptedReservationTimestamp,
    ]
  );

  const timePickerValues = useAvailableTimeValues(
    aggregatedShifts,
    turnTime,
    moment.unix(datePickerValue),
    moment.unix(earliestAcceptedReservationTimestamp),
    moment.unix(latestAcceptedReservationTimestamp)
  );

  const onSearchOpenSlots = async () => {
    if (timePickerValue === null) {
      return;
    }
    setIsFormSubmitting();

    const unixTimestamp =
      moment.unix(datePickerValue).startOf('day').unix() + timePickerValue;

    setAvailableTimeSlots(null);
    setPreferredTime(unixTimestamp);
    if (
      moment
        .unix(datePickerValue)
        .isSame(moment.unix(earliestAcceptedReservationTimestamp), 'day') &&
      timePickerValue <= (timePickerValues[0].value as unknown as number)
    ) {
      setIsEarliestSearchOption(true);
    } else {
      setIsEarliestSearchOption(false);
    }

    const { earliestStartingTime, latestStartingTime } =
      getTimeRangeForTimeSlot({
        unixTimestamp,
        maxLeadTime,
        minLeadTime,
        turnTime,
      });

    try {
      const { openReservationTimestamps } = await getOpenTimeSlots(
        hostUrl,
        discoverId,
        {
          earliestStartingTime,
          latestStartingTime,
          guestCount: selectedGuestCount,
        }
      );

      if (openReservationTimestamps.length > 0) {
        setAvailableTimeSlots(openReservationTimestamps);
      }
    } catch (error_) {
      // TODO: Add eror notification handling if open slots failed to fetch
      setAvailableTimeSlots(null);
      console.error(error_);
    }
    setIsFormSubmitting();
  };

  const isCurrentMonth = (date: Date) => {
    const currentCalendarMoment = moment.unix(currentCalendarTimeStamp);

    return (
      currentCalendarMoment.isSame(moment(date), 'month') &&
      currentCalendarMoment.isSame(moment(date), 'year')
    );
  };

  const renderDate = (date: Date) => {
    if (isCurrentMonth(date)) {
      return <div className="calendar-day">{date.getDate()}</div>;
    }

    return null;
  };

  return (
    <>
      <StyledName content={name} data-cy="reservation-location-name" />
      <SectionTitle>
        <UserOutlined style={buttonIconStyle} />
        {intl.formatMessage({
          id: 'reservations.reserveSlot.overview.guestCount',
        })}
      </SectionTitle>
      <GuestCountSelection maxGuestCount={maxGuestCount} />
      {selectedGuestCount > maxGuestCount ? (
        <GuestCountLimitInfo>
          <StyledPhoneOutlined />
          {intl.formatMessage(
            { id: 'reservations.maxGuestCount.exceeded' },
            { maxGuestCount }
          )}
          {publicPhone && (
            <div>
              <a onClick={event => event.stopPropagation()} href="tel:012312">
                {publicPhone}
              </a>
            </div>
          )}
        </GuestCountLimitInfo>
      ) : (
        <>
          <SectionTitle>
            <CalendarOutlined style={buttonIconStyle} />
            {intl.formatMessage({
              id: 'reservations.reserveSlot.overview.date',
            })}
          </SectionTitle>
          <TimeValue
            onClick={() => setShowCalendarView(!showCalendarView)}
            data-cy="reservation-date-value"
          >
            {moment.unix(datePickerValue).format('LL')}
          </TimeValue>

          <Calendar
            renderDate={renderDate}
            allowClear={false}
            selectionMode="single"
            weekStartsOn="Monday"
            defaultValue={moment
              .unix(earliestAcceptedReservationTimestamp)
              .toDate()}
            onChange={date => {
              if (date) {
                const value = moment(date).startOf('day').unix();
                setDatePickerValue(value);
                setShowCalendarView(false);
              }
            }}
            onPageChange={(year, month) => {
              setCurrentCalendarTimeStamp(
                moment()
                  .set('year', year)
                  .set('month', month - 1)
                  .unix()
              );
            }}
            minPage={{
              year: moment.unix(earliestAcceptedReservationTimestamp).year(),
              month:
                moment.unix(earliestAcceptedReservationTimestamp).month() + 1,
            }}
            maxPage={{
              year: moment.unix(latestAcceptedReservationTimestamp).year(),
              month:
                moment.unix(latestAcceptedReservationTimestamp).month() + 1,
            }}
            prevMonthButton={
              moment
                .unix(earliestAcceptedReservationTimestamp)
                .isBefore(
                  moment.unix(currentCalendarTimeStamp),
                  'month'
                ) ? undefined : (
                <NavigationPlaceholder />
              )
            }
            nextMonthButton={
              moment
                .unix(currentCalendarTimeStamp)
                .isSameOrAfter(
                  moment.unix(latestAcceptedReservationTimestamp),
                  'month'
                ) ? (
                <NavigationPlaceholder />
              ) : undefined
            }
            prevYearButton={null}
            nextYearButton={null}
            shouldDisableDate={checkIfDayIsDisabled}
            style={{ ...(showCalendarView ? {} : { display: 'none' }) }}
          />

          <SectionTitle>
            <ClockCircleOutlined style={buttonIconStyle} />
            {intl.formatMessage({
              id: 'reservations.reserveSlot.overview.time',
            })}
          </SectionTitle>
          {showCalendarView ? (
            <TimeValue
              onClick={() => setShowCalendarView(!showCalendarView)}
              data-cy="reservation-time-value"
            >
              {moment()
                .startOf('day')
                .add(timePickerValue, 'seconds')
                .format('HH:mm')}
            </TimeValue>
          ) : timePickerValues.length ? (
            isMobile ? (
              <StyledTimePicker
                value={`${Math.floor(timePickerValue / 60 / 60)}:${
                  (timePickerValue / 60) % 60
                }`}
                type="time"
                step="900"
                onChange={event => {
                  const [hours, minutes] = event.target.value
                    .split(':')
                    .map(Number);
                  const seconds = (hours * 60 + minutes) * 60;
                  setTimePickerValue(seconds);
                }}
              />
            ) : (
              <PickerView
                // @ts-ignore - PickerView typing is incorrect: PickerValue can be strings or numbers
                columns={[timePickerValues]}
                onChange={([time]) => {
                  if (typeof time === 'number') {
                    setTimePickerValue(time);
                  }
                }}
                style={{
                  '--height': '160px',
                }}
                mouseWheel
              />
            )
          ) : (
            <SectionTitle>
              {intl.formatMessage(
                { id: 'reservations.noShiftsAvailable' },
                { br: <br /> }
              )}
            </SectionTitle>
          )}

          <StyledPrimaryButton
            onClick={onSearchOpenSlots}
            loading={isFormSubmitting}
            disabled={
              isFormSubmitting ||
              maxGuestCount < selectedGuestCount ||
              typeof timePickerValue !== 'number' ||
              !timePickerValues.length
            }
            data-cy="reservation-search"
          >
            {intl.formatMessage({ id: 'search' })}
          </StyledPrimaryButton>
        </>
      )}
    </>
  );
};
